import  { type ReactNode, type HTMLProps, type ForwardedRef , forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Variant = 'Text1' | 'Text2' | 'Text3' | 'Text4' | 'Text5'
type TagType = 'h1' | 'h2' | 'p' | 'div' | 'span'

type TextProps<T extends TagType> = {
	variant: Variant
	tagType: T
	className?: string
	children: ReactNode
} & HTMLProps<HTMLElementTagNameMap[T]>

export const Text = forwardRef(function <T extends TagType>(
	{ variant, tagType, className, children, ...props }: TextProps<T>,
	ref: ForwardedRef<HTMLElementTagNameMap[T]> | undefined // Update ref type here
) {
	const variantClasses = {
		Text0: 'text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl',
		Text1: 'text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl',
		Text2: 'text-lg 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl',
		Text3: 'text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl',
		Text4: 'text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl',
		Text5: 'text-xs 2xl:text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl',
	}

	const classNames = twMerge(variantClasses[variant], className)

	if (tagType === 'h1') {
		return (
			<h1 ref={ref as ForwardedRef<HTMLHeadingElement> as any} className={classNames} {...props}>
				{children}
			</h1>
		)
	}

	if (tagType === 'h2') {
		return (
			<h2 ref={ref as ForwardedRef<HTMLHeadingElement> as any} className={classNames} {...props}>
				{children}
			</h2>
		)
	}

	if (tagType === 'p') {
		return (
			<p ref={ref as ForwardedRef<HTMLParagraphElement> as any} className={classNames} {...props}>
				{children}
			</p>
		)
	}

	if (tagType === 'div') {
		return (
			<div ref={ref as ForwardedRef<HTMLDivElement> as any} className={classNames} {...props}>
				{children}
			</div>
		)
	}

	if (tagType === 'span') {
		return (
			<span ref={ref as ForwardedRef<HTMLSpanElement>} className={classNames} {...props}>
				{children}
			</span>
		)
	}

	throw new Error(`Unsupported tag type: ${tagType}`)
})
